
.my-bg-black {
  background-color: #000000 !important;
}
.my-bg-white {
  background-color: #ffffff !important;
}
.my-bg-lime {
  background-color: #a4c400 !important;
}
.my-bg-green {
  background-color: #60a917 !important;
}
.my-bg-emerald {
  background-color: #008a00 !important;
}
.my-bg-teal {
  background-color: #00aba9 !important;
}
.my-bg-cyan {
  background-color: #1ba1e2 !important;
}
.my-bg-cobalt {
  background-color: #0050ef !important;
}
.my-bg-indigo {
  background-color: #6a00ff !important;
}
.my-bg-violet {
  background-color: #aa00ff !important;
}
.my-bg-pink {
  background-color: #dc4fad !important;
}
.my-bg-magenta {
  background-color: #d80073 !important;
}
.my-bg-crimson {
  background-color: #a20025 !important;
}
.my-bg-red {
  background-color: #e51400 !important;
}
.my-bg-orange {
  background-color: #fa6800 !important;
}
.my-bg-amber {
  background-color: #f0a30a !important;
}
.my-bg-yellow {
  background-color: #e3c800 !important;
}
.my-bg-brown {
  background-color: #825a2c !important;
}
.my-bg-olive {
  background-color: #6d8764 !important;
}
.my-bg-steel {
  background-color: #647687 !important;
}
.my-bg-mauve {
  background-color: #76608a !important;
}
.my-bg-taupe {
  background-color: #87794e !important;
}
.my-bg-gray {
  background-color: #555555 !important;
}
.my-bg-dark {
  background-color: #333333 !important;
}
.my-bg-darker {
  background-color: #222222 !important;
}
.my-bg-transparent {
  background-color: transparent !important;
}
.my-bg-darkBrown {
  background-color: #63362f !important;
}
.my-bg-darkCrimson {
  background-color: #640024 !important;
}
.my-bg-darkMagenta {
  background-color: #81003c !important;
}
.my-bg-darkIndigo {
  background-color: #4b0096 !important;
}
.my-bg-darkCyan {
  background-color: #1b6eae !important;
}
.my-bg-darkCobalt {
  background-color: #00356a !important;
}
.my-bg-darkTeal {
  background-color: #004050 !important;
}
.my-bg-darkEmerald {
  background-color: #003e00 !important;
}
.my-bg-darkGreen {
  background-color: #128023 !important;
}
.my-bg-darkOrange {
  background-color: #bf5a15 !important;
}
.my-bg-darkRed {
  background-color: #9a1616 !important;
}
.my-bg-darkPink {
  background-color: #9a165a !important;
}
.my-bg-darkViolet {
  background-color: #57169a !important;
}
.my-bg-darkBlue {
  background-color: #16499a !important;
}
.my-bg-lightBlue {
  background-color: #4390df !important;
}
.my-bg-lightRed {
  background-color: #ff2d19 !important;
}
.my-bg-lightGreen {
  background-color: #7ad61d !important;
}
.my-bg-lighterBlue {
  background-color: #00ccff !important;
}
.my-bg-lightTeal {
  background-color: #45fffd !important;
}
.my-bg-lightOlive {
  background-color: #78aa1c !important;
}
.my-bg-lightOrange {
  background-color: #c29008 !important;
}
.my-bg-lightPink {
  background-color: #f472d0 !important;
}
.my-bg-grayDark {
  background-color: #333333 !important;
}
.my-bg-grayDarker {
  background-color: #222222 !important;
}
.my-bg-grayLight {
  background-color: #999999 !important;
}
.my-bg-grayLighter {
  background-color: #eeeeee !important;
}
.my-bg-blue {
  background-color: #00aff0 !important;
}
.my-fg-black {
  color: #000000 !important;
}
.my-fg-white {
  color: #ffffff !important;
}
.my-fg-lime {
  color: #a4c400 !important;
}
.my-fg-green {
  color: #60a917 !important;
}
.my-fg-emerald {
  color: #008a00 !important;
}
.my-fg-teal {
  color: #00aba9 !important;
}
.my-fg-cyan {
  color: #1ba1e2 !important;
}
.my-fg-cobalt {
  color: #0050ef !important;
}
.my-fg-indigo {
  color: #6a00ff !important;
}
.my-fg-violet {
  color: #aa00ff !important;
}
.my-fg-pink {
  color: #dc4fad !important;
}
.my-fg-magenta {
  color: #d80073 !important;
}
.my-fg-crimson {
  color: #a20025 !important;
}
.my-fg-red {
  color: #e51400 !important;
}
.my-fg-orange {
  color: #fa6800 !important;
}
.my-fg-amber {
  color: #f0a30a !important;
}
.my-fg-yellow {
  color: #e3c800 !important;
}
.my-fg-brown {
  color: #825a2c !important;
}
.my-fg-olive {
  color: #6d8764 !important;
}
.my-fg-steel {
  color: #647687 !important;
}
.my-fg-mauve {
  color: #76608a !important;
}
.my-fg-taupe {
  color: #87794e !important;
}
.my-fg-gray {
  color: #555555 !important;
}
.my-fg-dark {
  color: #333333 !important;
}
.my-fg-darker {
  color: #222222 !important;
}
.my-fg-transparent {
  color: transparent !important;
}
.my-fg-darkBrown {
  color: #63362f !important;
}
.my-fg-darkCrimson {
  color: #640024 !important;
}
.my-fg-darkMagenta {
  color: #81003c !important;
}
.my-fg-darkIndigo {
  color: #4b0096 !important;
}
.my-fg-darkCyan {
  color: #1b6eae !important;
}
.my-fg-darkCobalt {
  color: #00356a !important;
}
.my-fg-darkTeal {
  color: #004050 !important;
}
.my-fg-darkEmerald {
  color: #003e00 !important;
}
.my-fg-darkGreen {
  color: #128023 !important;
}
.my-fg-darkOrange {
  color: #bf5a15 !important;
}
.my-fg-darkRed {
  color: #9a1616 !important;
}
.my-fg-darkPink {
  color: #9a165a !important;
}
.my-fg-darkViolet {
  color: #57169a !important;
}
.my-fg-darkBlue {
  color: #16499a !important;
}
.my-fg-lightBlue {
  color: #4390df !important;
}
.my-fg-lighterBlue {
  color: #00ccff !important;
}
.my-fg-lightTeal {
  color: #45fffd !important;
}
.my-fg-lightOlive {
  color: #78aa1c !important;
}
.my-fg-lightOrange {
  color: #c29008 !important;
}
.my-fg-lightPink {
  color: #f472d0 !important;
}
.my-fg-lightRed {
  color: #ff2d19 !important;
}
.my-fg-lightGreen {
  color: #7ad61d !important;
}
.my-fg-grayDark {
  color: #333333 !important;
}
.my-fg-grayDarker {
  color: #222222 !important;
}
.my-fg-grayLight {
  color: #999999 !important;
}
.my-fg-grayLighter {
  color: #eeeeee !important;
}
.my-fg-blue {
  color: #00aff0 !important;
}