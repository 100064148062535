/* You can add global styles to this file, and also import other style files */

*::-webkit-scrollbar {
    width: 6px !important;
    background-color: #cccccc !important;
}

*::-webkit-scrollbar-thumb {
    background-color: #1F6899 !important;
    border-radius: 0px !important;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #1F6899 !important;
    border: 1px solid #333333 !important;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #1F6899 !important;
    border: 1px solid #333333 !important;
}



.div-linha
{
    padding-top: 10px;
    padding-bottom: 10px;
}

input[type=text]
{
    height: 45px;
    padding-bottom: 10px;
    padding-left: 18px;
    padding-right: 40px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 7px 3px rgba(229,229,229,0.92); 
    box-shadow: 0px 0px 7px 3px rgba(229,229,229,0.92);
}

.hashtag-especialidade {
    background-color: #e7e7e7;
    color: rgb(44, 44, 44);
    padding-left: 5px !important; 
    padding-right: 5px !important;
    border-radius: 3px !important;
    margin-right: 3px;
    text-transform: lowercase;
    font-size: calc(60% + 0.05vw) !important;
}

  

.cursor-hand {
    cursor: pointer;
    overflow: visible
}

  
.icone-ajuda {
    position: absolute;
    top: 5px;
    margin-left: -9px;   
    opacity: 0.6;
    cursor: pointer;
    z-index: 9;
}